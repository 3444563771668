import React from 'react'
import {
      Navbar,
      Nav
} from 'react-bootstrap'


const Navigation = () => (
      <Navbar variant='light' className='white-color position-absolute w-100 z-depth-0' expand='lg' id='site-navbar'>
            <Navbar.Brand href='/' id='gtm-logo'><span className='brand-icon highlight-text'>A</span> enterprise 360</Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
                  <Nav as='ul' className='ml-auto text-right white-color gtm-menu' navbar>
                  <Nav.Item as='li' className = 'px-3'><Nav.Link href='/'>Home</Nav.Link></Nav.Item>
                  <Nav.Item as='li' className = 'px-3'><Nav.Link href='/tour'>Tour</Nav.Link></Nav.Item>
                  <Nav.Item as='li' className = 'px-3'><Nav.Link href='/pricing'>Pricing</Nav.Link></Nav.Item>
                  <Nav.Item as='li' className = 'px-3'><Nav.Link href='/contact'>Contact</Nav.Link></Nav.Item>
            </Nav>
            </Navbar.Collapse>
      </Navbar>
)

export default Navigation
