import React from "react"
import { 
    Container, 
    Row, 
    Col
} from "react-bootstrap"
import EWIF from '../../assets/images/ewif.jpg'

const Footer = ({ pageInfo }) => {
    return (
        <>
            <Container fluid className="black-color">
                <Container className="py-4"></Container>
                <Container id="footer">
                    <Row className="white-text" id="gtm-f360-footer">
                        <Col>
                            <h6 className="pb-3">Web and Social</h6>
                            <p className="pb-1"><a className="white-text" href="http://www.twitter.com/franchise360">Twitter</a></p>
                            <p className="pb-1"><a className="white-text" href="https://www.facebook.com/franchise360/">Facebook</a></p>
                        </Col>
                        <Col>
                            <h6 className="pb-3">Get in Touch</h6>
                            <p className="pb-1"><a className="white-text" href="/contact/">0800 804 7473</a></p>
                            <p className="pb-1"><a className="white-text" href="/contact/">More Contact Details</a></p>
                            <p className="pb-1"><a className="orange-text" href="mailto: info@greyridge.com" title="email us">info@greyridge.com</a></p>
                        </Col>
                        <Col>
                            <h6 className="pb-3">External</h6>
                            <a href="https://www.ewif.org/" className=""><img className="mb-3" src={EWIF} alt="EWIF Website"/></a>
                            <p className="pb-1 "><a className="white-text" href="https://unsplash.com/ ">Photographs: Unsplash</a></p>
                        </Col>
                        <Col>
                            <h6 className="pb-3 ">Miscellaneous</h6>
                            <p className="pb-1 "><a className="white-text" href="http://greyridge.com/ ">&copy; 2020 <span itemProp="name ">GreyRidge</span></a></p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Footer