import React from "react"
import {
      Button
} from "react-bootstrap"


const SideContact = () => (
<Button href="/contact" id="side-contact" className="highlight-color white-text rounded gtm-button">
     Contact Us
</Button>
)

export default SideContact
