import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/elements/seo"
import SideContact from "../components/elements/sideContact"
import Navigation from "../components/elements/nav"
import Footer from "../components/elements/footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO title="Home" keywords={[`Software`, `franchise software`, `ISO Software`]} />
        <SideContact/>
        <div><Navigation/></div>
          {children} 
        <Footer/>
      </>
    )}
  />
)

export default Layout
